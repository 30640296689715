const state = {
    cacheIndex: parseInt(Math.random() * 10000),

    selectedDate:         null,
    modalAddNewVisible:   false,
    calendarRefreshIndex: 0,
    preSelectedEmployee:  [],

    // Drag and drop related data
    modalDragDropChangeDateVisible: false,
    eventUuidToChangeDate:          null,
    dragDropOldDate:                null,
    dragDropNewDate:                null,

    dummyRefreshVariable: 0,
    startDateSelected:    null,
    modalEditNewVisible:  false,
    eventToEditUuid:      null,

    incrementDate:  0,
    decrementDate:  0,
    weekEventsData: [],
};

const getters = {
};

const actions = {
};

const mutations = {
    updateCurrentDate(state, payload) {
        state.selectedDate = payload;
    },
    showAddNewModal(state, payload) {
        state.startDateSelected = payload.startDateSelected;
        state.modalAddNewVisible = true;
        state.preSelectedEmployee = payload.preSelectedEmployee;
    },
    showModalDragDropChangeDateVisible(state, payload) {
        state.eventUuidToChangeDate = payload.uuid;
        state.dragDropOldDate = payload.oldDate;
        state.dragDropNewDate = payload.newDate;
        state.modalDragDropChangeDateVisible = true;
    },
    closeModalDragDropChangeDateVisible(state) {
        state.modalDragDropChangeDateVisible = false;
    },
    closeModalAddNewEvent(state) {
        state.modalAddNewVisible = false;
    },
    refreshCalendarEvents(state) {
        state.calendarRefreshIndex += 1;
    },

    showEditNewModal(state, payload) {
        state.modalEditNewVisible = true;
        state.eventToEditUuid = payload;
    },
    closeModalEditNewEvent(state) {
        state.modalEditNewVisible = false;
    },
    incrementDate(state) {
        state.incrementDate++;
    },
    decrementDate(state) {
        state.decrementDate++;
    },
    updateWeekEventsData(state, payload) {
        state.weekEventsData = payload;
    },
    incrementDummyRefreshVariable(state) {
        state.dummyRefreshVariable++;
    },
    incrementCacheIndex(state) {
        state.cacheIndex++;
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};
