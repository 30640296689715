import Vue from 'vue';
import Vuex from 'vuex';
import Calendar from './calendar';
import Economy from './economy';
import Messages from './messages';
import User from './user';
import Keys from './keys';

Vue.use(Vuex);

const menuCollapse = () => {
    if (window.innerWidth < 600) {
        return true;
    }
    return localStorage.getItem('menuCollapsed') ? JSON.parse(localStorage.getItem('menuCollapsed')) : false;
};

export default new Vuex.Store({
    state: {
        menuCollapsed:       menuCollapse(),
        activeMenu:          '1',
        title:               'Workflows.se',
        eventConfirmation:   1,
        roomsUnreadMessages: [],
        lastChatMessage:     {},
        roomSelected:        null,

        managersDropdown:          [],
        clientsActiveDropdown:     [],
        clientsArchivedDropdown:   [],
        employeesActiveDropdown:   [],
        employeesArchivedDropdown: [],

        confirmationVisible:    false,
        confirmationActionType: 'archive',
        confirmationActionUrl:  '',
    },
    mutations: {
        toggleMenuStatue(state) {
            localStorage.setItem('menuCollapsed', !state.menuCollapsed);
            state.menuCollapsed = !state.menuCollapsed;
        },
        setActiveMenuItem(state, payload) {
            state.activeMenu = payload;
        },
        setPageTitle(state, title) {
            state.title = title;
        },
        setActiveClientsDropdown(state, payload) {
            state.clientsActiveDropdown = payload;
        },
        setArchivedClientsDropdown(state, payload) {
            state.clientsArchivedDropdown = payload;
        },
        setActiveEmployeesDropdown(state, payload) {
            state.employeesActiveDropdown = payload;
        },
        setArchivedEmployeesDropdown(state, payload) {
            state.employeesArchivedDropdown = payload;
        },
        setManagersDropdown(state, payload) {
            state.managersDropdown = payload;
        },
        showConfirmationModal(state, payload) {
            state.confirmationVisible = true;
            state.confirmationActionType = payload.actionType ? payload.actionType : 'archive';
            state.confirmationActionUrl = payload.actionUrl;
        },
        closeConfirmationModal(state) {
            state.confirmationVisible = false;
            state.confirmationActionUrl = '';
        },

        setRoomsUnreadMessages(state, payload) {
            state.roomsUnreadMessages = payload;
        },

        markRoomAsRead(state, roomId) {
            const test = state.roomsUnreadMessages.findIndex(room => room.room_id === roomId);
            state.roomsUnreadMessages[test].count = 0;
        },

        incrementUnreadMessagesForRoom(state, roomId) {
            if (state.roomSelected === roomId) return;
            const test = state.roomsUnreadMessages.findIndex(room => room.room_id === roomId);
            state.roomsUnreadMessages[test].count++;
        },

        updateLastChatMessage(state, message) {
            state.lastChatMessage = message;
        },

        setSelectedRoomId(state, roomId) {
            state.roomSelected = roomId;
        },
    },
    actions: {
    },

    modules: {
        calendar: Calendar,
        messages: Messages,
        user:     User,
        economy:  Economy,
        keys:     Keys,
    },
});
