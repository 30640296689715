import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faSpinnerThird,
    faShareAll,
    faChartPie as faChartPieDueTone,
    faFolderOpen,
    faHomeAlt as faHomeAltDueTone,
    faEmptySet,
    faBusinessTime,
    faSackDollar as faSackDollarDueTone,
    faHistory as faHistoryDueTone,
    faCloudUploadAlt,
    faComments,
    faBan,
    faUserSecret as faUserSecretDueTone,
    faSpinner,
    faFragile,
    faBellOn,
    faMapMarkedAlt,
    faUsers as faUsersDueTone,
    faHome as faHomeDueTone,
    faHandsHelping as faHandsHelpingDueTone,
    faFileInvoiceDollar as faFileInvoiceDollarDueTone,
} from '@fortawesome/pro-duotone-svg-icons';

import {
    faTimes as falTimes,
    faTrain as falTrain,
    faClock,
} from '@fortawesome/pro-light-svg-icons';

import {
    faUserSecret,
    faInfo,
    faUsers,
    faArchive,
    faRedo,
    faTrash,
    faHomeAlt,
    faCommentDots,
    faKey,
    faFireAlt,
    faFilePdf,
    faPrint,
    faMap,
    faUserCog,
    faChartLine,
    faBroom as faBroomSolid,
    faUserPlus,
    faChartNetwork as fasChartNetwork,
    faCalendarAlt as faCalendarAltSolid,
    faInfoCircle,
    faMinusCircle,
    faCheckCircle,
    faUserCircle,
    faMapMarker,
    faUser,
    faFilter,
    faShip,
    faBus,
    faSubway,
    faTrain,
    faChartPie,
    faPaperPlane,
    faCog,
    faChild,
    faShoppingCart,
    faStar,
    faAngry,
    faPencil,
    faDownload,
    faExclamationCircle,
    faEye,
    faEyeSlash,
    faCheck,
    faTimes,
    faMinus,
    faArrowCircleRight,
    faCar,
    faLevelUpAlt,
    faMapMarkerAlt,
    faQuestionCircle,
    faEnvelope as fasEnvelope
} from '@fortawesome/pro-solid-svg-icons';

import {
    faEnvelope,
    faPowerOff,
    faBell,
    faChartNetwork,
    faSackDollar,
    faMoneyCheckEditAlt,
    faCalendarAlt,
    faFileUpload,
    faFileSignature,
    faHistory,
    faBroom,
    faEdit,
    faSync,
    faPlus,
    faTrain as farTrain,
    faLifeRing,
    faRobot,
    faAngleLeft,
    faAngleRight,
    faDrawPolygon,
    faCreditCard,
    faWalking,
    faChevronRight,
    faChevronLeft,
    faSearch,
    faInboxIn,
    faInboxOut,
    faRedoAlt,
    faArrows,
} from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    faUserSecret,
    faPowerOff,
    faBell,
    faEnvelope,
    fasEnvelope,
    faInfo,
    faUsers,
    faArchive,
    faRedo,
    faTrash,
    faChartNetwork,
    faHomeAlt,
    faSackDollar,
    faMoneyCheckEditAlt,
    faCommentDots,
    faCalendarAlt,
    faKey,
    faFireAlt,
    faFileUpload,
    faFileSignature,
    faHistory,
    faBroom,
    faEdit,
    faSync,
    faHistory,
    faFilePdf,
    faPrint,
    faInfo,
    faMap,
    faUserCog,
    faChartLine,
    faBroomSolid,
    faUserPlus,
    fasChartNetwork,
    faCalendarAltSolid,
    faPlus,
    faInfoCircle,
    faMinusCircle,
    faCheckCircle,
    faUserCircle,
    faMapMarker,
    faUser,
    faFilter,
    faShip,
    faBus,
    faSubway,
    faTrain,
    farTrain,
    falTrain,
    faChartPie,
    faTimes,
    faPaperPlane,
    faRobot,
    faLifeRing,
    faAngleLeft,
    faAngleRight,
    faCog,
    faDrawPolygon,
    faCreditCard,
    faWalking,
    faChild,
    faChevronRight,
    faChevronLeft,
    faSearch,
    faSpinnerThird,
    faShareAll,
    faShoppingCart,
    faInboxIn,
    faInboxOut,
    faStar,
    faAngry,
    faRedoAlt,
    faPencil,
    faArrows,
    faDownload,
    faChartPieDueTone,
    faFolderOpen,
    faHomeAltDueTone,
    faEmptySet,
    faBusinessTime,
    faSackDollarDueTone,
    faHistoryDueTone,
    faCloudUploadAlt,
    faComments,
    faBan,
    faExclamationCircle,
    faUserSecretDueTone,
    faSpinner,
    faEye,
    faEyeSlash,
    faFragile,
    faCheck,
    falTimes,
    faMinus,
    faArrowCircleRight,
    faCar,
    faClock,
    faBellOn,
    faLevelUpAlt,
    faMapMarkerAlt,
    faMapMarkedAlt,
    faUsersDueTone,
    faQuestionCircle,
    faExclamationCircle,
    faHomeDueTone,
    faHandsHelpingDueTone,
    faFileInvoiceDollarDueTone,
);

Vue.component('fa-icon', FontAwesomeIcon);
