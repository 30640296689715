import Vue from 'vue';
// import lang from 'element-ui/lib/locale/lang/en';
import langSe from 'element-ui/lib/locale/lang/sv-SE';
import locale from 'element-ui/lib/locale';

import {
    Button,
    ButtonGroup,
    Tooltip,
    Loading,
    Input,
    ColorPicker,
    Notification,
    Row,
    Col,
    Table,
    TableColumn,
    Select,
    Option,
    OptionGroup,
    Dialog,
    Form,
    FormItem,
    Switch,
    Radio,
    RadioButton,
    RadioGroup,
    InputNumber,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Pagination,
    Timeline,
    TimelineItem,
    Upload,
    MessageBox,
    Popover,
    DatePicker,
    Slider,
    Step,
    Steps,
    Rate,
    Tag,
    Transfer,
    Checkbox,
    CheckboxGroup,
    Tabs,
    TabPane,
    Drawer,
    Badge,
} from 'element-ui';

// TODO: fix language
locale.use(langSe);

Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Tooltip);
Vue.use(Input);
Vue.use(ColorPicker);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(InputNumber);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Pagination);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Upload);
Vue.use(Popover);
Vue.use(DatePicker);
Vue.use(Slider);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Rate);
Vue.use(Tag);
Vue.use(Transfer);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Drawer);
Vue.use(Badge);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$notify = Notification;
Vue.prototype.$ELEMENT = { size: 'small' };
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
