import keysApi from '@/api/keys2';

const state = {
    keysData: [],
};

const getters = {
    keysDataForTable: state => filter => {
        if (!filter) return state.keysData;
        return state.keysData.filter(key => (
            key.name.toLowerCase().includes(filter.toLowerCase())
                || (key.description && key.description.toLowerCase().includes(filter.toLowerCase()))
                || key.address.toLowerCase().includes(filter.toLowerCase())
                || key.client.toLowerCase().includes(filter.toLowerCase())
                || key.holder.toLowerCase().includes(filter.toLowerCase())
        ));
    },
};

const actions = {
    async getAll({ commit, dispatch }) {
        dispatch('wait/start', 'keys.loading', { root: true });
        await keysApi.getAll(data => commit('setKeysData', data));
        dispatch('wait/end', 'keys.loading', { root: true });
        dispatch('wait/end', 'loading.initial_keys', { root: true });
    },
};

const mutations = {
    setKeysData(state, payload) {
        state.keysData = Object.freeze(payload);
    },
    clearKeysData(state) {
        state.keysData = [];
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};
