import axios from 'axios';
import axiosCancel from 'axios-cancel';
import Cookies from 'js-cookie';
import { Notification } from 'element-ui';

import store from '../store/store';

axiosCancel(axios);

const apiToken = Cookies.get('api_token');
axios.defaults.baseURL = process.env.VUE_APP_CORE_URL;
axios.defaults.headers.common.Authorization = `Bearer ${apiToken}`;

const showErrors = errors => {
    Object.keys(errors).forEach(async key => {
        await new Promise(done => setTimeout(done, 200));
        Notification.warning({
            title:    'Warning',
            message:  errors[key][0],
            duration: 10000,
        });
    });
};

axios.interceptors.request.use(config => {
    if (['patch', 'post', 'delete'].includes(config.method) && config.url.includes('services/calendar/')) {
        store.commit('calendar/incrementCacheIndex');
    }

    return config;
}, error => Promise.reject(error));

axios.interceptors.response.use(
    response => response,
    error => {
        if (axios.isCancel(error)) {
            return;
            // return Promise.reject(error);
        }

        const loginUrl = `${process.env.VUE_APP_CORE_API_URL}/login`;
        // If page is login page, skip advanced error handling
        if (loginUrl === error.config.url) {
            return Promise.reject(error);
        }

        if (error.response?.status === 401 && process.env.VUE_APP_ENV === 'production') {
            Cookies.remove('api_token');
            window.location = '/login';
        }

        if (!error.response.data.errors) {
            store.dispatch('wait/end', 'updating');

            Notification.error({
                title:    'Error',
                message:  error.response.data.message,
                duration: 5000,
            });
        }
        showErrors(error.response.data.errors);

        return Promise.reject(error);
    },
);

window.axios = axios;
