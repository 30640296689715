module.exports = {
    general() {
        return {
            path:      '/settings/general',
            name:      'settings_general',
            component: () => import(/* webpackChunkName: "SettingsGeneralView" */ '@/views/settings_general/SettingsGeneral.vue'),
        };
    },
    economy() {
        return {
            path:      '/settings/economy',
            name:      'settings_economy',
            component: () => import(/* webpackChunkName: "SettingsEconomyView" */ '@/views/settings_economy/SettingsEconomy.vue'),
            children:  [
                {
                    path:      'general',
                    name:      'settings_economy_general',
                    component: () => import(/* webpackChunkName: "EconomyGeneralFormView" */ '@/views/settings_economy/components/EconomyGeneralForm.vue'),
                },
                {
                    path:      'accepted_payment_methods',
                    name:      'settings_economy_accepted_payment_methods',
                    component: () => import(/* webpackChunkName: "AcceptedPaymentMethodsView" */ '@/views/settings_economy/components/AcceptedPaymentMethods.vue'),
                },
                {
                    path:      'invoice_logo',
                    name:      'settings_economy_invoice_logo',
                    component: () => import(/* webpackChunkName: "EconomyLogoView" */ '@/views/settings_economy/components/EconomyLogo.vue'),
                },
                {
                    path:      'fortnox_integration',
                    name:      'settings_economy_fortnox_integration',
                    component: () => import(/* webpackChunkName: "EconomyFortnoxIntegration" */ '@/views/settings_economy/components/FortnoxIntegration.vue'),
                },
            ],
        };
    },
    payments() {
        return {
            path:      '/settings/payments',
            name:      'settings_payments',
            component: () => import(/* webpackChunkName: "SettingsPaymentsView" */ '@/views/settings_payments/SettingsPayments.vue'),
        };
    },
    rolesAndPermissions() {
        return {
            path:      '/settings/roles_and_permissions',
            name:      'settings_roles_and_permissions',
            component: () => import(/* webpackChunkName: "SettingsRolesAndPermissionsView" */ '@/views/settings_roles_and_permissions/SettingsRolesAndPermissions.vue'),
        };
    },
};
