module.exports = {
    emails() {
        return {
            path:      '/communication_emails',
            name:      'communication_emails',
            component: () => import(/* webpackChunkName: "EmailsView" */ '@/views/communication_emails/Emails.vue'),
        };
    },
    messages() {
        return {
            path:      '/messages',
            name:      'messages',
            component: () => import(/* webpackChunkName: "MessagesView" */ '@/views/communication_messages/Messages.vue'),
        };
    },
};
