import mixins from '../mixins';

const Employees = {
    index() {
        return {
            path:      '/employees_all',
            name:      'employees_all',
            component: () => import(/* webpackChunkName: "EmployeesAllView" */ '@/views/employees_all/EmployeesAll.vue'),
        };
    },
    create() {
        return {
            path:      '/employees_create',
            name:      'employees_create',
            component: () => import(/* webpackChunkName: "EmployeesCreateView" */ '@/views/employees_create/EmployeesCreate.vue'),
        };
    },
    archived() {
        return {
            path:      '/employees_archived',
            name:      'employees_archived',
            component: () => import(/* webpackChunkName: "EmployeesArchivedView" */ '@/views/employees_archived/EmployeesArchived.vue'),
        };
    },
    history() {
        return {
            path:      '/employees_history',
            name:      'employees_history',
            component: () => import(/* webpackChunkName: "EmployeesHistoryView" */ '@/views/employees_history/EmployeesHistory.vue'),
        };
    },
    failureCheckIn() {
        return {
            path:      '/employees_failure_check_in',
            name:      'employees_failure_check_in',
            component: () => import(/* webpackChunkName: "EmployeesHistoryView" */ '@/views/employees_failure_check_in/EmployeesFailureCheckIn.vue'),
        };
    },
    absence() {
        return {
            path:      '/employees_absence',
            name:      'employees_absence',
            component: () => import(/* webpackChunkName: "EmployeesAbsenceView" */ '@/views/employees_absence/EmployeesAbsence.vue'),
        };
    },
    details() {
        return {
            path:      '/employees/:uuid',
            name:      'employees_details',
            component: () => import(/* webpackChunkName: "EmployeeDetailsView" */ '@/views/employees_details/EmployeeDetails.vue'),
            children:  [
                {
                    path:      'information',
                    name:      'employees_details_information',
                    component: () => import(/* webpackChunkName: "EmployeeInformationView" */ '@/views/employees_details/employee_information/EmployeeInformation.vue'),
                },
                {
                    path:      'assigned_addresses',
                    name:      'employee_assigned_addresses',
                    component: () => import(/* webpackChunkName: "EmployeeAssignedAddressesView" */ '@/views/employees_details/assigned_addresses/AssignedAddresses.vue'),
                },
                {
                    path:      'files',
                    name:      'employees_details_files',
                    component: () => import(/* webpackChunkName: "EmployeeFilesView" */ '@/views/employees_details/employee_files/EmployeeFiles.vue'),
                },
                {
                    path:      'address',
                    name:      'employees_details_address',
                    component: () => import(/* webpackChunkName: "EmployeeAddressView" */ '@/views/employees_details/employee_address/EmployeeAddress.vue'),
                },
                {
                    path:      'keys',
                    name:      'employees_details_keys',
                    component: () => import(/* webpackChunkName: "EmployeeKeysView" */ '@/views/employees_details/employee_keys/EmployeeKeys.vue'),
                },
                {
                    path:      'history',
                    name:      'employees_details_history',
                    component: () => import(/* webpackChunkName: "EmployeeHistoryView" */ '@/views/employees_details/employee_history/EmployeeHistory.vue'),
                },
                {
                    path:      'comments',
                    name:      'employees_details_comments',
                    component: () => import(/* webpackChunkName: "EmployeeCommentsView" */ '@/views/employees_details/employee_comments/EmployeeComments.vue'),
                },
                {
                    path:      'exclude',
                    name:      'employees_details_exclude',
                    component: () => import(/* webpackChunkName: "EmployeeExcludeView" */ '@/views/employees_details/employee_exclude/EmployeeExclude.vue'),
                },
                {
                    path:      'damages',
                    name:      'employees_details_damages',
                    component: () => import(/* webpackChunkName: "EmployeeDamagesView" */ '@/views/employees_details/employee_damages/EmployeeDamages.vue'),
                },
                {
                    path:      'assigned_service_categories',
                    name:      'employees_details_assigned_service_categories',
                    component: () => import(/* webpackChunkName: "EmployeeAssignedServiceCategoriesView" */ '@/views/employees_details/employee_assigned_service_categories/EmployeeAssignedServiceCategories.vue'),
                },
                {
                    path:      'reminders',
                    name:      'employees_details_reminders',
                    component: () => import(/* webpackChunkName: "EmployeeRemindersView" */ '@/views/employees_details/employee_reminders/EmployeeReminders.vue'),
                },
                {
                    path:      'emergency_contacts',
                    name:      'employees_emergency_contacts',
                    component: () => import(/* webpackChunkName: "EmployeeEmergencyContactsView" */ '@/views/employees_details/emergency_contacts/EmergencyContacts.vue'),
                },
            ],
        };
    },
};

export default Employees;
