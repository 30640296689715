import Cookies from 'js-cookie';
import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

if (process.env.VUE_APP_BUGSNAG_KEY) {
    const userEmail = Cookies.get('user_email');
    Bugsnag.start({
        apiKey:              process.env.VUE_APP_BUGSNAG_KEY,
        logger:              null,
        autoCaptureSessions: false,
        autoTrackSessions:   false,
        plugins:             [new BugsnagPluginVue()],
        user:                {
            email: userEmail,
        },
        onError(event) {
            if (event.severity !== 'error') return false;
            if (event.severityReason?.type !== 'unhandledPromiseRejection') return false;
            if (event.originalError === 'ResizeObserver loop limit exceeded') return false;
        },
    });

    const bugsnagVue = Bugsnag.getPlugin('vue');
    bugsnagVue.installVueErrorHandler(Vue);
}
