const state = {
    detailsView:     false,
    selectedStatus:  'all',
    selectedType:    'all',
    invoicesNumbers: {
        all:       0,
        draft:     0,
        confirmed: 0,
        sent:      0,
        unpaid:    0,
        paid:      0,
        rut_sent:  0,
        completed: 0,
    },
    invoicesNumbersByType: {
        all:      0,
        transfer: 0,
        autogiro: 0,
        einvoice: 0,
        reminder: 0,
        credited: 0,
    },
    selectedInvoices: [],
};

const getters = {
};

const actions = {
};

const mutations = {
    toggleDetailsView(state, payload) {
        state.detailsView = payload;
    },
    updateInvoicesNumbers(state, payload) {
        state.invoicesNumbers = payload;
    },
    updateInvoicesNumbersByType(state, payload) {
        state.invoicesNumbersByType = payload;
    },
    updateSelectedStatus(state, payload) {
        state.selectedStatus = payload;
    },
    updateSelectedType(state, payload) {
        state.selectedType = payload;
    },
    updateSelectedInvoices(state, payload) {
        state.selectedInvoices = payload;
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};
