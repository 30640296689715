module.exports = {
    salaries() {
        return {
            path:      '/salaries',
            name:      'salaries',
            component: () => import(/* webpackChunkName: "SalariesView" */ '@/views/economy_salaries/Salaries.vue'),
        };
    },
    salaryDetails() {
        return {
            path:      '/salaries/:uuid',
            name:      'salary_details',
            component: () => import(/* webpackChunkName: "SalaryDetailsView" */ '@/views/economy_salaries/SalaryDetails.vue'),
        };
    },
    invoices() {
        return {
            path:      '/invoices',
            name:      'invoices',
            component: () => import(/* webpackChunkName: "InvoicesView" */ '@/views/economy_invoices/Invoices.vue'),
        };
    },
    autogiro() {
        return {
            path:      '/autogiro',
            name:      'autogiro',
            component: () => import(/* webpackChunkName: "AutogiroFilesView" */ '@/views/economy_autogiro/AutogiroFiles.vue'),
        };
    },
    rutFiles() {
        return {
            path:      '/rut_files',
            name:      'rut_files',
            component: () => import(/* webpackChunkName: "RutFilesView" */ '@/views/economy_rut_files/RutFiles.vue'),
        };
    },
};
