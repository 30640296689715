const state = {
    user: {
        user_uuid:             '',
        master_uuid:           '',
        type:                  '',
        company_type:          '',
        language:              'se',
        welcome_title:         '',
        calendar_confirmation: 1,
        auto_client_custom_id: 1,
        country:               null,
        permissions:           [],
        notifications:         [],
        menuCollapsed:         false,
    },
};

const getters = {
};

const actions = {
};

const mutations = {
    updateUser(state, user) {
        state.user = user;
    },
    pushNewNotification(state, payload) {
        state.user.notifications = [{
            id:   payload.id,
            data: {
                icon:    payload.icon,
                title:   payload.title,
                content: payload.content,
                date:    payload.date,
                time:    payload.time,
            },
        }, ...state.user.notifications];
    },

    removeAllNotifications(state) {
        state.user.notifications = [];
    },

    removeNotificationFromUnseen(state, payload) {
        state.user.notifications = state.user.notifications.filter(item => item.id !== payload);
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};
