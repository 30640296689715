import Vue from 'vue';
import Router from 'vue-router';
import Cookies from 'js-cookie';
import Dashboard from './dashboard';
import Calendar from './calendar';
import GeneralMap from './map';
import Clients from './clients';
import Employees from './employees';
import Managers from './managers';
import Keys from './keys';
import Communication from './communication';
import Services from './services';
import ServicesCategories from './services_categories';
import Economy from './economy';
import Statistics from './statistics';
import HelpDesk from './help_desk';
import Settings from './settings';
import Assets from './assets';

Vue.use(Router);

const router = new Router({
    mode:   'history',
    base:   process.env.BASE_URL,
    routes: [
        {
            path:      '/',
            redirect:  { name: 'dashboard' },
            component: () => import('@/views/AppLayout.vue'),
            children:  [
                Dashboard.index(),
                Dashboard.notifications(),
                Calendar.index(),
                GeneralMap.index(),
                Clients.index(),
                Clients.archived(),
                Clients.create(),
                Clients.history(),
                Clients.damages(),
                Clients.details(),
                Keys.index(),
                Keys.archive(),
                Employees.index(),
                Employees.create(),
                Employees.archived(),
                Employees.history(),
                Employees.failureCheckIn(),
                Employees.absence(),
                Employees.details(),
                Managers.index(),
                Managers.archived(),
                Managers.create(),
                Managers.details(),
                Communication.emails(),
                Communication.messages(),
                Services.index(),
                ServicesCategories.index(),
                Economy.salaries(),
                Economy.salaryDetails(),
                Economy.rutFiles(),
                Economy.invoices(),
                Economy.autogiro(),
                Statistics.index(),
                HelpDesk.index(),
                HelpDesk.details(),
                Settings.general(),
                Settings.economy(),
                Settings.payments(),
                Settings.rolesAndPermissions(),
                Assets.cars(),
                Assets.carsArchived(),
            ],
        },
        {
            path:      '/login',
            name:      'login',
            component: () => import(/* webpackChunkName: "LoginPageView" */ '@/views/LoginPage.vue'),
        },
        {
            path:      '/password_reset',
            name:      'password_reset',
            component: () => import(/* webpackChunkName: "PasswordResetPageView" */ '@/views/PasswordResetPage.vue'),
        },
        {
            path:      '/password_reset_complete',
            name:      'password_reset_complete',
            component: () => import(/* webpackChunkName: "PasswordResetCompletePageView" */ '@/views/PasswordResetCompletePage.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    axios.cancelAll();

    const apiToken = Cookies.get('api_token');
    const userUuid = Cookies.get('user_uuid');

    if (to.name === 'password_reset' || to.name === 'password_reset_complete') {
        next();
        return;
    }
    // if (from.name === 'login' && to.name === 'password_reset') {
    //     next();
    //     return;
    // }

    if ((apiToken || userUuid) && to.name === 'login') {
        next('/');
        return;
    }

    if ((!apiToken || !userUuid) && to.name !== 'login') {
        Cookies.remove('api_token');
        Cookies.remove('user_uuid');
        next('login');
        return;
    }

    next();
});

export default router;
