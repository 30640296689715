const routes = {
    index() {
        return {
            path:      '/clients',
            name:      'clients_all',
            component: () => import(/* webpackChunkName: "ClientsAll" */ '@/views/clients_all/ClientsAll.vue'),
        };
    },
    archived() {
        return {
            path:      '/clients/archived',
            name:      'clients_archived',
            component: () => import(/* webpackChunkName: "ClientsArchived" */ '@/views/clients_all/ClientsArchived.vue'),
        };
    },
    create() {
        return {
            path:      '/clients/create',
            name:      'clients_create',
            component: () => import(/* webpackChunkName: "ClientsCreate" */ '@/views/clients_create/ClientsCreate.vue'),
        };
    },
    history() {
        return {
            path:      '/clients/history',
            name:      'clients_history',
            component: () => import(/* webpackChunkName: "ClientsHistory" */ '@/views/clients_history/ClientsHistory.vue'),
        };
    },
    damages() {
        return {
            path:      '/clients/damages',
            name:      'clients_damages',
            component: () => import(/* webpackChunkName: "ClientsDamages" */ '@/views/clients_damages/ClientsDamages.vue'),
        };
    },
    details() {
        return {
            path:      '/clients/:uuid',
            name:      'client_details',
            component: () => import(/* webpackChunkName: "ClientDetails" */ '@/views/clients_details/ClientDetails.vue'),
            children:  [
                {
                    path:      'dashboard',
                    name:      'client_dashboard',
                    component: () => import(/* webpackChunkName: "ClientInformation" */ '@/views/clients_details/dashboard/Dashboard.vue'),
                },
                {
                    path:      'information',
                    name:      'client_information',
                    component: () => import(/* webpackChunkName: "ClientInformation" */ '@/views/clients_details/information/Information.vue'),
                },
                {
                    path:      'billing_information',
                    name:      'client_billing_information',
                    component: () => import(/* webpackChunkName: "ClientBillingInformation" */ '@/views/clients_details/billing_information/BillingInformation.vue'),
                },
                {
                    path:      'addresses',
                    name:      'client_addresses',
                    component: () => import(/* webpackChunkName: "ClientAddresses" */ '@/views/clients_details/addresses/Addresses.vue'),
                },
                {
                    path:      'addresses/:address_uuid',
                    name:      'client_address_details',
                    component: () => import(/* webpackChunkName: "ClientAddressesDetails" */ '@/views/clients_details/addresses/AddressesDetails.vue'),
                },
                {
                    path:      'history',
                    name:      'client_history',
                    component: () => import(/* webpackChunkName: "ClientHistory" */ '@/views/clients_details/history/History.vue'),
                },
                {
                    path:      'keys',
                    name:      'client_keys',
                    component: () => import(/* webpackChunkName: "ClientKeys" */ '@/views/clients_details/keys/Keys.vue'),
                },
                {
                    path:      'notes_comments',
                    name:      'client_notes_comments',
                    component: () => import(/* webpackChunkName: "ClientNotesComments" */ '@/views/clients_details/notes_comments/NotesComments.vue'),
                },
                {
                    path:      'damages',
                    name:      'client_damages',
                    component: () => import(/* webpackChunkName: "ClientDetailsDamages" */ '@/views/clients_details/damages/Damages.vue'),
                },
                {
                    path:      'communication',
                    name:      'client_communication',
                    component: () => import(/* webpackChunkName: "ClientCommunication" */ '@/views/clients_details/communication/ClientCommunication.vue'),
                },
                {
                    path:      'activity_logs',
                    name:      'activity_logs',
                    component: () => import(/* webpackChunkName: "ActivityLogs" */ '@/views/clients_details/activity_logs/ActivityLogs.vue'),
                },
                {
                    path:      'map_poi',
                    name:      'client_map_poi',
                    component: () => import(/* webpackChunkName: "ClientMapPOI" */ '@/views/clients_details/map_poi/MapPOI.vue'),
                },
                {
                    path:      'files',
                    name:      'client_files',
                    component: () => import(/* webpackChunkName: "ClientFiles" */ '@/views/clients_details/files/Files.vue'),
                },
                {
                    path:      'emergency_contacts',
                    name:      'client_emergency_contacts',
                    component: () => import(/* webpackChunkName: "ClientEmergencyContacts" */ '@/views/clients_details/emergency_contacts/EmergencyContacts.vue'),
                },
                {
                    path:      'assigned_employees',
                    name:      'client_assigned_employees',
                    component: () => import(/* webpackChunkName: "ClientAssignedEmployees" */ '@/views/clients_details/assigned_employees/AssignedEmployees.vue'),
                },
                {
                    path:      'invoices',
                    name:      'client_invoices',
                    component: () => import(/* webpackChunkName: "ClientInvoices" */ '@/views/clients_details/invoices/Invoices.vue'),
                },
                {
                    path:      'invoices/:invoiceId',
                    name:      'client_invoices_details',
                    component: () => import(/* webpackChunkName: "ClientInvoicesDetails" */ '@/views/clients_details/invoices/InvoicesDetails.vue'),
                },
                {
                    path:      'fortnox',
                    name:      'client_fortnox',
                    component: () => import(/* webpackChunkName: "ClientFortnox" */ '@/views/clients_details/fortnox/Fortnox.vue'),
                },
            ],
        };
    },
};

export default routes;
