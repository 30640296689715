module.exports = {
    index() {
        return {
            path:      '/dashboard',
            name:      'dashboard',
            component: () => import(/* webpackChunkName: "DashboardView" */ '@/views/dashboard/Dashboard.vue'),
        };
    },
    notifications() {
        return {
            path:      '/notifications',
            name:      'notifications',
            component: () => import(/* webpackChunkName: "NotificationsView" */ '@/views/dashboard/Notifications.vue'),
            props:     route => ({ query: route.query }),
        };
    },
};
