module.exports = {
    index() {
        return {
            path:      '/managers',
            name:      'managers_all',
            component: () => import(/* webpackChunkName: "ManagersAlView" */ '@/views/managers_all/ManagersAll.vue'),
        };
    },
    archived() {
        return {
            path:      '/managers_archived',
            name:      'managers_archived',
            component: () => import(/* webpackChunkName: "ManagersArchivView" */ '@/views/managers_archive/ManagersArchive.vue'),
        };
    },
    create() {
        return {
            path:      '/managers_create',
            name:      'managers_create',
            component: () => import(/* webpackChunkName: "ManagersCreateView" */ '@/views/managers_create/ManagersCreate.vue'),
        };
    },
    details() {
        return {
            path:      '/managers/:uuid',
            name:      'manager_details',
            component: () => import(/* webpackChunkName: "ManagerDetailsView" */ '@/views/managers_details/ManagerDetails.vue'),
            children:  [
                {
                    path:      'dashboard',
                    name:      'manager_details_dashboard',
                    component: () => import(/* webpackChunkName: "DashboardView" */ '@/views/managers_details/manager_dashboard/Dashboard.vue'),
                },
                {
                    path:      'general',
                    name:      'manager_details_general',
                    component: () => import(/* webpackChunkName: "ManagerGeneralView" */ '@/views/managers_details/manager_information/ManagerGeneral.vue'),
                },
                {
                    path:      'employees',
                    name:      'manager_details_employees',
                    component: () => import(/* webpackChunkName: "ManagerEmployeesView" */ '@/views/managers_details/manager_employees/ManagerEmployees.vue'),
                },
            ],
        };
    },
};
