const state = {
    selectedUser: {
        name: '',
    },
};

const getters = {
};

const actions = {
};

const mutations = {
    updateSelectedUser(state, user) {
        state.selectedUser = user;
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};
