import Vue from 'vue';
import indexOf from 'lodash/indexOf';
import accounting from 'accounting-js';
import humanizeDuration from 'humanize-duration';
import ms from 'milliseconds';
import store from '@/store/store';

const mixins = {
    methods: {
        userCan(permission) {
            return indexOf(store.state.user.user.permissions, permission) >= 0;
        },

        userIsBuildingCompany() {
            return this.$store.state.user.user.company_type === 'building';
        },

        userIsCleaningCompany() {
            return this.$store.state.user.user.company_type === 'cleaning';
        },

        intToMoneyFormat(value) {
            const currency = Cookies.get('currency') ? Cookies.get('currency') : 'kr';
            return accounting.formatMoney(value / 100, {
                symbol: currency, decimal: ',', thousand: ' ', precision: 2, format: '%v%s',
            });
        },

        moneyToIntFormat(value) {
            return accounting.toFixed(Number(value.replace(',', '.')), 2) * 100;
        },

        normalizeTime(minutes, days = false) {
            const locale = Cookies.get('language') ? Cookies.get('language') : 'se';
            const units = days ? ['d', 'h', 'm'] : ['h', 'm'];
            return humanizeDuration(ms.minutes(minutes), {
                spacer:    '',
                delimiter: ' ',
                units,
                language:  locale,
                languages: {
                    se: {
                        d: () => 'd',
                        h: () => 't',
                        m: () => 'm',
                    },
                    en: {
                        d: () => 'd',
                        h: () => 'h',
                        m: () => 'm',
                    },
                    pl: {
                        d: () => 'd',
                        h: () => 'g',
                        m: () => 'm',
                    },
                },
            });
        },
    },
};

Vue.mixin(mixins);

export default mixins;
