module.exports = {
    index() {
        return {
            path:      '/help_desk',
            name:      'help_desk',
            component: () => import(/* webpackChunkName: "HelpDeskView" */ '@/views/help_desk/HelpDesk.vue'),
        };
    },
    details() {
        return {
            path:      'help_desk/:uuid',
            component: () => import(/* webpackChunkName: "HelpDeskView" */ '@/views/help_desk/HelpDesk.vue'),
            children:  [
                {
                    path:      'article',
                    component: () => import(/* webpackChunkName: "ArticleView" */ '@/views/help_desk/Article.vue'),
                },
            ],
        };
    },
};
