import Vue from 'vue';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';

const lang = Cookies.get('language') === 'se' ? 'sv' : Cookies.get('language');

require('dayjs/locale/sv');
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.locale(lang);

Vue.prototype.$dayjs = dayjs;
