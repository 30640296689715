module.exports = {
    index() {
        return {
            path:      '/keys',
            name:      'keys',
            component: () => import(/* webpackChunkName: "KeysView" */ '@/views/clients_keys/Keys.vue'),
        };
    },
    archive() {
        return {
            path:      '/keys_archived',
            name:      'keys_archived',
            component: () => import(/* webpackChunkName: "KeysArchiveView" */ '@/views/clients_keys/KeysArchive.vue'),
        };
    },
};
