module.exports = {
    cars() {
        return {
            path:      '/cars_all',
            name:      'cars_all',
            component: () => import(/* webpackChunkName: "CarsAllView" */ '@/views/cars/CarsAll.vue'),
        };
    },

    carsArchived() {
        return {
            path:      'cars_archived',
            name:      'cars_archived',
            component: () => import(/* webpackChunkName: "CarsArchivedView" */ '@/views/cars/CarsArchived.vue'),
        };
    },
};
