import Vue from 'vue';
import VueWait from 'vue-wait';
import Cookies from 'js-cookie';
import './mixins';
import router from './router/router';
import App from './App.vue';
import store from './store/store';
import i18n from './plugins/i18n';
import './plugins/bugsnag';
import './plugins/dayjs';
import './plugins/element';
import './plugins/axios';
// import './plugins/pusher';
import './plugins/font-awesome';
// import './assets/tailwind.css';
import './assets/build.css';

require('default-passive-events');

Vue.config.productionTip = false;
window.Cookies = Cookies;

Vue.use(VueWait);
new Vue({
    store,
    router,
    i18n,
    wait: new VueWait({
        useVuex: true,
    }),
    render: h => h(App),
}).$mount('#app');
